import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Leo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Leo Health
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/leo"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Leo</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Leo Health</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Leo Health</h1>
                <h2 className="text-sm md:text-base ml-4">July 23 - August 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-love");
                  }}>
                  <h5 className="font-semibold text-black">Leo Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-nature");
                  }}>
                  <h5 className="font-semibold text-black">Leo Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-man");
                  }}>
                  <h5 className="font-semibold text-black">Leo Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Leo Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-traits");
                  }}>
                  <h5 className="font-semibold text-black">Leo Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-facts");
                  }}>
                  <h5 className="font-semibold text-black">Leo Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
           <p className="playfair text-black text-xl md:text-3xl">Leo Health</p>
           <br></br>
           The Leo sign rules the upper back, forearms, wrist, spine, and heart. The natives have great energy and power. Commonly, you enjoy perfect health, but unfortunately, if you suffer from any illnesses, you tend to recuperate faster. You want to live a carefree life. You are adventurous but need to be more careful about your diet and workout. Troubles and pains connected to muscles, heart, spine, and stomach are likely to disturb you. You love spending time in a modern cycling course, barre class, or functioning physical activities alone. You can complete your fitness goal by having a running friend, belonging to a fitness class on social media, or working with a private sports trainer. <br></br>
           <br></br>
           You are known for longevity due to the powerful impact of the Sun. The planet characterized your growth, power, energy, and good health. Usually, Leos do not get suffer as you are a fitness freak. But if Sun is tormented in your horoscope, you may have to face specific health issues. Will you be fighting fit in the year 2024? Access your free personalized 2024 yearly report by the best astrologer! <br></br>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Possible Health Concerns</p>
           <br></br>
           Leo natives often run high fevers and are inclined to accidents. You are prone to sud­den dangerous illnesses and lingering infections or disorders. Usually, you are immune to chronic. You may have accidents to the legs or ankles, issues relating to the reproductive organs, hoarseness, or irritated throats. <br></br> 
           <br></br>
           You may have disorders related to blood and gut, illness in ribs and sides, convulsions, and pleasantries. You may suffer from a nemesis, pestilence, jaundice, and some distress and discomforts of the eyes. You usually move so hard that you may suffer stress from overexertion and nerves. <br></br>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Food for Leo</p> 
           <br></br>
           It would be best if you kept a good intake of magnesium phosphate in your dietary habit that forms blood albumen, maintains the fluidity of the blood, and activates the digestive enzymes. Some examples include whole grains, rice, citrus fruits, apples, peaches, potatoes, carrots, radishes, spinach, broccoli, nuts, almonds, sunflower seeds, and figs that hold magnesium phosphate. You can also contain beef, lamb, poultry, liver, fresh fruit, salad greens, cheese, whole milk, and yogurt. These meal items will help blood circulation and maintain blood-making properties. You should add iron-rich foods to your healthy diet like spinach, raisins, and dates. You can decrease your heart strain by including plums, pears, oranges.<br></br>
           <br></br>
           It would be best if you cut down quantities of gluten, fatty and spicy foods, and dairy products. You must spare time in your active and hectic schedule, eat your meals at the correct time, and try not to skip your meals. 
           2024 Yearly Report gives you the best of the guidance to direct yourself in a way that can surely improve your life with certain decisions related to your health and get your free personalized 2024 <br></br>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Physical appearance</p>
           <br></br>
           Leos have catlike elements and are nearly continuously very good-looking. Your mane of hair usually cleans back off the face and gives a deceptively lethargic look. You are often tall; if you are not, you will majestically carry yourself. You walk straight with dignity and a soft glide. This conveys a powerful reality to you in an overcrowded place. You usually have blue or dark brown eyes. Your eyes look dull and delicate initially, and then it cracks with fire. It is rounded in shape and barely tilted at the comer. Your hair is likely wavy, worn in a wild, careless style that upsweeps, stands out entirely on the top and the sides, or is slicked down tightly. <br></br>
           <br></br>
           <p className="playfair text-black text-xl md:text-3xl">Leo beauty</p>
           <br></br>
           Leo's beauty spins around their crowning praise. Your outstanding hair enriches your beauty. You boast a great heart that pushes affection and creativeness. You want to be center set with your lively and spunky charm. Hence, the gaze-grabbing purple shimmer look is the focal pinpoint of your awe-inspiring fashion. Orange and gold colors are best worked for the Lions, who continually seek to allure awareness. Golden mascara sweetens the beauty of their eyes.  <br></br>
           <br></br>
           Navy blue, gold, or red nail polish conveys your sensuality and supervision qualities. Bright-colored attire adds to your ostentation. You love your shades to be solid, deep, dark, and even shiny. You are not afraid to comment on your dresses, although you are particular about what sort of statement you make and how.
           You add conviction to your general impression and theatrical stage presence, forming a confident, larger-than-life image. You are an attention seeker; hence, adding a bright pink blush to your cheeks' apples enhances the all-around glow. It will also draw awareness to the eyes. You have a bold vision and a slightly dramatic cheek. You want to go impartial with your lip option. You are pretty more adventurous with your eyes. Liquid eyeliner, smoky eyes, and red lips will perfectly match your persona. <br></br>
           <br></br>
           Each sign has personality traits that make it stand out from the crowd. What's yours? Know with your personalized Janampatri. Access your customized horoscope from the best astrologer! <br></br>
           <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
